<script lang="jsx">
export default {
  name: 'behaviour-list-item',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    renderDesc(descList) {
      return (
        <ol class="info-desc">
          {
            descList.map((item, index) => {
              return (
                <li key={index}>
                  <span class="info-desc__dist"></span>
                  <p class="info-desc__text">
                    { item.title }
                    <em>{ parseFloat(item.score) || 0 }分</em>
                  </p>
                </li>
              )
            })
          }
        </ol>
      )
    },
  },

  render(h) {
    return (
      <div class="behaviour-list-item">
        {
          this.data.monthList.map((item, index) => {
            return (
              <div key={index} class="item-right__info">
                <div class="item-right__info-left">
                  <h3 class="info-title line-h-20">{ item.month }</h3>
                  {
                    item.detailList && item.detailList.length > 0 ? this.renderDesc(item.detailList) : ''
                  }
                </div>
                <div class={['item-right__info-right', Math.abs(item.score) > 0 ? 'red': '']}>{ parseFloat(item.score) || 0 }<i>分</i></div>
              </div>
            )
          })
        }
      </div>
    )
  }
}
</script>

<style lang="scss">
.behaviour-list-item {
  .item-right {
    &__info {
      margin-left: 15px;
      flex: auto;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $linecolor;
      padding-bottom: 10px;
      padding-top: 20px;
      &:first-of-type {
        padding-top: 5px;
      }
      &:last-of-type {
        border-bottom: none;
      }
      &-left {
        flex: 1;
        .info-title {
          margin-bottom: 12px;
          font-size: 18px;
          font-weight: bold;
        }
        .info-desc {
          font-size: 14px;
          font-weight: 400;
          color: $darkgrey;
          line-height: 14px;
          list-style-type: disc;
          li {
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
          }
          &__dist {
            width: 2px;
            height: 2px;
            display: block;
            background: $darkgrey;
            margin-right: 10px;
            margin-left: 4px;
            margin-top: 8px;
            flex: none;
          }
          &__text {
            line-height: 20px;
            word-break: break-all;
          }
          em {
            font-size: 14px;
            font-weight: bold;
            color: $lightblack;
            line-height: 14px;
            margin-left: 15px;
          }
        }
      }
      &-right {
        flex: none;
        align-items: center;
        color: $grey;
        font-weight: bold;
        i {
          font-size: 12px;
          font-weight: 400;
          color: $grey;
          line-height: 12px;
        }
        &.red {
          color: $red;
          i {
            color: $red;
          }
        }
      }
    }
  }
}
</style>
