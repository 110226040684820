<template>
  <div class="behaviour-query-view">
    <!-- <tojoy-title-content title="行为规范" /> -->
    <div class="behaviour-query-view__content">
      <tojoy-pagination-list :loading="loading" :nodata-schema="nodataSchema" :data="behaviourData.list" :total="behaviourData.total" @page-change="handleChangePage">
        <template #item="{data}">
          <tojoy-card schema="people" :data="data">
            <template #upcard>
              <h3 class="font-20 font-w-500 line-h-20 mb-15">{{ data.year + '年' }}</h3>
            </template>
            <template #downcard>
              <behaviour-list-item :data="data" />
            </template>
          </tojoy-card>
        </template>
      </tojoy-pagination-list>
      <month-evaluate :data="monthData" :isEvaluate="isEvaluate" @submit="handleSubmit" />
    </div>
  </div>
</template>

<script>
import TojoyCard from '@/components/business/card'
import TojoyPaginationList from '@/components/business/pagination-list'
// import TojoyTitleContent from '@/components/common/initiate-team/title-content'
import BehaviourListItem from '@/components/behaviour/list-item'
import MonthEvaluate from '@/components/behaviour/month-evaluate'
import { actionScoreList, selfEvaluateQuery, selfEvaluateSubmit } from '@/api/api_behaviour'

export default {
  name: 'behaviour-query',
  components: {
    TojoyCard,
    TojoyPaginationList,
    // TojoyTitleContent,
    BehaviourListItem,
    MonthEvaluate
  },
  data() {
    return {
      behaviourData: [],
      nodataSchema: 'behaviour-notdata',
      monthData: {},
      total: 0,
      pagination: {
        page: 1,
        size: 10
      },
      loading: false,
      isEvaluate: 1
    }
  },
  created() {
    this.loadActionScoreList({ ...this.pagination })
    this.loadSelfEvaluateQuery()
    console.log('001', this.$route.query.isDeductionSelf)
    this.isEvaluate = this.$route.query.isDeductionSelf
  },
  methods: {
    /**
     * 加载行为规范数据
     */
    async loadActionScoreList(params, options = {}) {
      this.loading = true
      const { code, data } = await actionScoreList(params, options)
      if (code === '000000') {
        this.behaviourData = data
        this.nodataSchema = 'behaviour-notdata'
      } else {
        this.nodataSchema = 'notdata'
      }
      this.loading = false
    },
    /**
     * 加载月度自评查询
     */
    async loadSelfEvaluateQuery() {
      const { code, data } = await selfEvaluateQuery()
      if (code === '000000') {
        this.monthData = data
      }
    },
    /**
     * 提交月度自评
     */
    async handleSubmit({ list }) {
      const { code } = await selfEvaluateSubmit({ list })
      if (code === '000000') {
        this.$msgSuccess('提交成功')
        this.monthData.isFinsh = 1
      }
    },

    handleChangePage(val) {
      this.loadActionScoreList({ page: val, size: this.pagination.size }, { loading: false })
    }
  }
}
</script>

<style lang="scss">
.behaviour-query-view {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  &__content {
    display: flex;
    height: 100%;
    border-radius: 6px;
    padding: 20px 30px 0;
    overflow: auto;
  }
  .tojoy-pagination-list {
    flex: 1;
    &__wrap {
      border-radius: 5px 0 0 0;
      margin: 0;
      padding: 0;
      background: $white;
    }
    &__notdata {
      margin: 0;
    }
    &__loading {
      margin: 0;
    }
  }
  .month-list {
    flex: 1;
    height: 100%;
    background: $white;
    padding: 20px 15px;
    overflow: auto;
  }
  .font-20 {
    font-size: 20px;
  }
  .font-w-500 {
    font-weight: bold;
  }
  .line-h-20 {
    line-height: 20px;
  }
  .mb-15 {
    margin-bottom: 15px;
  }
}
</style>
