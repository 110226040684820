import * as http from './http'

// 行为规范-列表获取
export const actionScoreList = (params, options) =>
  http.post('/deduction/action/score/list', params, options)

// 行为规范-月度自评-查询接口
export const selfEvaluateQuery = params =>
  http.post('/deduction/action/score/self/evaluate', params)

// 行为规范-月度自评-提交接口
export const selfEvaluateSubmit = params =>
  http.post('/deduction/action/score/save/evaluate', params)
