<script lang="jsx">
const RADIO_VALUE = {
  0: {
    color: 'red',
    title: '未做到'
  },
  1: {
    color: 'blue',
    title: '基本做到'
  },
  2: {
    color: 'green',
    title: '完全做到'
  },
}
export default {
  name: 'month-evaluate',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isEvaluate: {
      type: String,
      default: ''
    }
  },
  computed: {
    isFinish() {
      return !!this.data.isFinsh
    },
  },
  methods: {
    radioOption(value) {
      return RADIO_VALUE[value]
    },
    listRender() {
      return this.data?.list?.map((item, index) => {
        const {color, title} = this.radioOption(Number(item.achieve))
        return (
          <div class='month-evaluate__item'>
            <h4 class='label'>{ item.title }</h4>
            <p class={['value', color]}>{title}</p>
          </div>
        )
      })
    },
    handleSubmit() {
      console.log('111');
      this.$refs['dynamicForm'].validate((valid) => {
        if (valid) {
          this.$emit('submit', {list: this.data.list})
        } else {
          return false;
        }
      });
    },
    goMonth() {
      console.log('222');
      this.$refs['dynamicForm'].validate((valid) => {
        if (valid) {
          // 返回上一页 ，路径上的参数不会丢失
          this.$emit('submit', {list: this.data.list})
          // this.$router.push('/thrclear/writemonth?reportType=3&name=写月清')
          this.$router.go(-1)
        } else {
          return false;
        }
      });
    }
  },
  render(h) {
    console.log('11',this.data);
    const attrs = {model: this.data}
    console.log('attrs',attrs);
    const {isEvaluate } = this
    return (
      <section class="month-evaluate">
        <h3>月度自评</h3>
        <div class="month-evaluate__wrap">
        {
          this.isFinish ? (this.listRender()) : (
          <el-form
            {...{attrs}}
            ref="dynamicForm"
            label-position="top"
            class="demo-dynamic">
            {
              this.data?.list?.map((domain, index) => {
                return (
                  <el-form-item
                    key={domain.id}
                    prop={'list.' + index + '.achieve'}
                    label={domain.title}
                    rules={{required: true, message: '请进行自评', trigger: 'change'}}
                  >
                    <el-radio-group v-model={domain.achieve} size="small">
                      <el-radio-button label={0}>未做到</el-radio-button>
                      <el-radio-button label={1}>基本做到</el-radio-button>
                      <el-radio-button label={2}>完全做到</el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                )
              })
            }
          </el-form>
          )
        }
        </div>
        <div class="submit-btn">
        {
          isEvaluate === null ?
          <el-button
            type="primary"
            onClick={this.goMonth}>
            提交并返回到月清
          </el-button>:
          <el-button
            disabled={this.isFinish}
            type="primary"
            onClick={this.handleSubmit}>
            {this.isFinish ? '本月已提交' : '提交'}
          </el-button>

        }


        </div>
      </section>
    )
  }
}
</script>

<style lang="scss">
.month-evaluate {
  display: flex;
  flex-direction: column;
  width: 20%;
  min-width: 380px;
  background: $white;
  border-left: 1px solid $linecolor;
  h3 {
    padding: 15px 15px 0;
  }
  &__wrap {
    flex: 1;
    overflow: auto;
    .el-form-item {
      margin-bottom: 0;
      padding: 25px 20px 20px;
      border-bottom: 1px solid $linecolor;
      &__label {
        line-height: 22px;
        color: $lightblack;
        word-break: break-all;
      }
    }
    .el-radio-group {
      margin-top: 8px;
      display: flex;
      .el-radio-button {
        flex: 1;
      }
      .el-radio-button__inner {
        font-size: 14px;
        font-weight: 400;
        line-height: 13px;
        width: 113px;
      }
      .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        color: $blue;
        background: $white;
      }
    }
  }
  &__item {
    border-bottom: 1px solid $linecolor;
    padding: 25px 20px 20px;
    .label {
      font-size: 14px;
      font-weight: 400;
      color: $lightblack;
      line-height: 22px;
      margin-bottom: 20px;
      word-break: break-all;
    }
    .value {
      font-size: 14px;
      font-weight: bold;
      line-height: 14px;
      &.red {
        color: $red;
      }
      &.blue {
        color: $blue;
      }
      &.green {
        color: $green;
      }
    }
  }
  .submit-btn {
    background: $white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 5px 0px;
    padding: 10px;
    text-align: center;
    .el-button {
      width: 167px;
      padding: 14px 20px;
    }
  }
}
</style>
